import request from "tools/request.js"

//商品设置 提交
class shangpin_set_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		 
	 
		let news_name 		= that.parent.news_name; 
		let html 					= that.parent.html;
		let m_news_id 			 	= that.parent.m_news_id; 
		let news_jituan_id =  that.parent.news_jituan_id;
		let news_dingzhi =  that.parent.news_dingzhi;
		let session 				= localStorage.getItem("session"); 
		  
		let params = { 
			news_name : news_name,
			html : html,
			m_news_id : m_news_id,
			news_jituan_id : news_jituan_id,
			news_dingzhi : news_dingzhi,
			session : session
		} 
		
		request.post('/news/news_set_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message,
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_2 = false;
			that.parent.parent.news_get.m_main(); 
		})
		 
	}
}

export default shangpin_set_post