<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			<br />







			<el-row>
				<el-col class="yu_input_label" :span="8">
					新闻标题:
				</el-col>
				<el-col :span="16"> 
					<el-input v-model="news_name"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					内容:
				</el-col>
				<el-col :span="16">
					 <div style="border: 1px solid #ccc;">
					        <Toolbar
					            style="border-bottom: 1px solid #ccc"
					            :editor="editor"
					            :defaultConfig="toolbarConfig"
					            :mode="mode"
					        />
					        <Editor
					            style="height: 500px; overflow-y: hidden;"
					            v-model="html"
					            :defaultConfig="editorConfig"
					            :mode="mode"
					            @onCreated="onCreated"
					        />
					    </div>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					集团id:
				</el-col>
				<el-col :span="16"> 
					<el-input v-model="news_jituan_id"></el-input>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					置顶:
				</el-col>
				<el-col :span="16"> 
					 
					<el-select  v-model="m_zhi_text"  @change="m_fenlei_change" placeholder="请选择" style="width: 100%;">
						<el-option v-for="item in zhiding_list" :key="item.zhi_id" :label="item.zhi_text"
							:value="item.zhi_id">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<br />
			<el-row class="yu_content_right">
				<el-button @click="news_add" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
	import { IEditorConfig } from '@wangeditor/editor'
	import news_add_post from "./expand/news_add_post.js"; 
	
	 
	const editorConfig = {                       // JS 语法
	    MENU_CONF: {} 
	}
	
	editorConfig.MENU_CONF['uploadImage'] = {
	    server: 'http://106.75.13.203:4035/upload2',
	    fieldName: 'img' 
	}
	 
	export default { 
		components: { Editor, Toolbar },
		props: ["parent"],
		data() {
			return {
				news_name:"", 
				 editor: null,
				 m_zhi_text: 0,
				 zhiding_list:[{
					 "zhi_id":0,
					 "zhi_text":"否"
				 },
				 {
				 					 "zhi_id":1,
				 					 "zhi_text":"是"
				 }],
				 news_jituan_id : 0,
				            html: '<p>hello</p>',
				            toolbarConfig: { },
				            editorConfig: editorConfig,
				            mode: 'default', // or 'simple'
			}
		},
		created() {
			const that = this;
			// that.news_add_get = new news_add_get(that);
			// that.news_add_get.m_main();
		},
		methods: {
			onCreated(editor) {
			            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
						
						// editor.getMenuConfig('uploadImage');
						
			        },
			news_add() {
				const that = this;
				that.news_add_post = new news_add_post(that);
				that.news_add_post.m_main();
			},
			handleAvatarSuccess(res, file) {
				const that = this;
				that.m_img2 = res.img_val;
				this.m_img = URL.createObjectURL(file.raw);
			},
			handleAvatarSuccess_1(res, file) {
				const that = this;
				that.m_img2_1 = res.img_val;
				this.m_img_1 = URL.createObjectURL(file.raw);
			},
			handleAvatarSuccess_2(res, file) {
				const that = this;
				that.m_img2_2 = res.img_val;
				this.m_img_2 = URL.createObjectURL(file.raw);
			},
			handleAvatarSuccess_3(res, file) {
				const that = this;
				that.m_img2_3 = res.img_val;
				this.m_img_3 = URL.createObjectURL(file.raw);
			},
			handleAvatarSuccess_4(res, file) {
				const that = this;
				that.m_img2_4 = res.img_val;
				this.m_img_4 = URL.createObjectURL(file.raw);
			},
			handleAvatarSuccess_5(res, file) {
				const that = this;
				that.m_img2_5 = res.img_val;
				this.m_img_5 = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			m_fenlei_change(){
				const that = this; 
			},
		},
		mounted() {
		        // 模拟 ajax 请求，异步渲染编辑器
		        // setTimeout(() => {
		        //     this.html = '<p>请输入新闻内容</p>'
		        // }, 1500)
		    },
		    beforeDestroy() {
		        const editor = this.editor
		        if (editor == null) return
		        editor.destroy() // 组件销毁时，及时销毁编辑器
		    }
	}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>